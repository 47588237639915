@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MatterRegular';
  src: url('../fonts/Matter-Regular.otf');
}

@font-face {
  font-family: 'MatterBold';
  src: url('../fonts/Matter-Bold.ttf');
}

@font-face {
  font-family: 'MarkBold';
  src: url('../fonts/Mark-Bold.woff2');
}

body {
  @apply font-matter-regular;
}

main {
  @apply flex-grow;
}

nav, header {
  @apply container;
}

h1 {
  @apply font-mark-bold text-[32px] text-center lg:text-5xl lg:text-left leading-10;
}

p {
  @apply text-base xl:text-2xl;
}

section .content {
  @apply grid lg:grid-cols-2 py-16 lg:py-[64px] xl:py-[112px] gap-6 lg:gap-4 xl:gap-20 container px-6 lg:px-0;
}

section h2 {
  @apply font-mark-bold text-2xl xl:text-[32px];
}

section .divider {
  @apply border-b-4 border-pkst-rose w-16 max-w-16 rounded-full my-3;
}

section p {
  @apply w-full lg:w-[350px] xl:w-2/3;
}

footer {
  @apply bg-pkst-gray py-6 lg:px-8 xl:px-0 text-sm;
}

footer a {
  @apply text-pkst-rose;
}

footer li a {
  @apply max-h-5;
}

h1, h2 {
  @apply font-mark-bold;
}

form {
  @apply w-full;
}

.menu {
  @apply flex items-center p-6 xl:py-6;
}

.nav {
  @apply lg:gap-10 flex flex-col lg:flex-row lg:items-center;
}

.nav a {
  @apply text-pkst-rose capitalize block relative px-4 py-5 lg:p-0 rounded-[2px];
}

/* .nav a:focus {
  @apply outline outline-pkst-rose outline-2;
} */

.active {
  @apply pointer-events-none;
}

.active::after {
  @apply absolute left-0 w-full bg-pkst-rose !opacity-100;
  content: '';
  height: 0.1em;
  bottom: -0.2em !important;
}

.nav a::after {
  @apply absolute bottom-0 left-0 w-full bg-pkst-rose opacity-0;
  content: '';
  height: 0.1em;
  transition: opacity 300ms, transform 300ms;
}

.nav a:hover::after {
  @apply opacity-100;
  transform: translate3d(0, 0.2em, 0);
}

.nav li:first-child {
  @apply mb-6 lg:mb-0;
}

.btn {
  @apply 
    flex
    items-center
    justify-center
    bg-pkst-rose 
    text-white 
    rounded-full 
    px-8 
    py-[10px] 
    transition-colors
    duration-500 
    hover:bg-pkst-dark-pink
    focus:outline 
    focus:outline-pkst-rose 
    focus:outline-[3px] 
    focus:bg-pkst-dark-pink
    w-full 
    xl:w-fit;
}

.secondary {
  @apply w-full bg-transparent border border-pkst-rose text-pkst-rose hover:text-white;
}

.box {
  @apply w-[120px] h-[55px] lg:w-[240px] lg:h-[110px] ml-auto mr-auto bg-[#FF0068] rounded-xl lg:rounded-3xl absolute -bottom-4 lg:-bottom-10 right-[100px] lg:right-52;
}

.circle {
  @apply size-32 lg:size-60 bg-pkst-pink rounded-full ml-auto mr-auto absolute -top-10;
}