
.btn-hambuguer {
  @apply w-5 cursor-pointer;
}

span {
  @apply block w-full h-[2px] bg-pkst-rose transition-all duration-300 relative rounded-full;
}

span + span {
  margin-top: 4px;
}

.active span:nth-child(1) {
  animation: ease .7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
